'use client'

import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  useMemo,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { Button } from 'base-ui'

import { useGlobalConfigContext } from '../../../global-config'
import { crossSellAccessorGetLocalizedProductDeepLink } from '../../model/crossSellAccessorGetLocalizedProductDeepLink'
import { CrossSellProductCard } from '../CrossSellProductCard'

type CrossSellGridProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
  onGtmDataLayerPush?: (cswProductId: CrossSellProduct['id']) => void
  products?: CrossSellProduct[]
}

const MOBILE_SKELETON_ITEM_COUNT = 4
const PRODUCTS_PER_PAGE = 8

export const CrossSellGrid = forwardRef<HTMLDivElement, CrossSellGridProps>(
  function CrossSellGrid(
    { className, onGtmDataLayerPush, products, ...rest },
    ref,
  ) {
    const { language, tenant } = useGlobalConfigContext()

    const [itemVisibleCount, setItemVisibleCount] = useState(PRODUCTS_PER_PAGE)

    const [items, totalProductsCount] = useMemo((): [
      items: CrossSellProduct[] | number[],
      count: number,
    ] => {
      /**
       * number[] will be returned to show skeleton items before csw call returns actual items
       */
      if (!products) {
        return [[...Array.from({ length: PRODUCTS_PER_PAGE }).keys()], 0]
      }

      const availableProducts = products.filter((product) =>
        crossSellAccessorGetLocalizedProductDeepLink({
          language,
          product,
          tenant,
        }),
      )

      return [
        availableProducts.slice(0, itemVisibleCount),
        availableProducts.length,
      ]
    }, [itemVisibleCount, language, products, tenant])

    return (
      <div
        className={clsx(
          'flex flex-col items-center gap-4 tablet:gap-6',
          className,
        )}
        ref={ref}
        {...rest}
      >
        <ul className="grid w-full grid-cols-2 gap-4 tablet:grid-cols-4 tablet:gap-y-6">
          {items.map((item, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions -- not for user interaction
            <li
              key={typeof item === 'number' ? item : item.id}
              onClick={
                onGtmDataLayerPush
                  ? (event): void => {
                      if (
                        event.target instanceof HTMLElement &&
                        event.target.closest('a') &&
                        // if item is a number, it's a skeleton item
                        typeof item !== 'number'
                      ) {
                        onGtmDataLayerPush(item.id)
                      }
                    }
                  : undefined
              }
            >
              <CrossSellProductCard
                className={clsx(
                  'h-full',
                  typeof item === 'number' &&
                    index >= MOBILE_SKELETON_ITEM_COUNT &&
                    'hidden tablet:block',
                )}
                product={typeof item === 'number' ? undefined : item}
              />
            </li>
          ))}
        </ul>
        {itemVisibleCount < totalProductsCount && (
          <Button
            onClick={() => {
              setItemVisibleCount((prevState) => prevState + PRODUCTS_PER_PAGE)
            }}
            size="large"
            variant="outlined"
          >
            <FormattedMessage id="general.label.loadMoreProducts" />
          </Button>
        )}
      </div>
    )
  },
)
