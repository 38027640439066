'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import {
  CrossSellGrid,
  CrossSellMetadataProvider,
  CrossSellWidgetContainer,
  useCrossSellIntersection,
  useSwrCrossSellHorizontalSlider,
} from '../../../cross-sell'
import { gtmDataLayerPushCswProduct } from '../../../gtm'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokCrossSellGridType } from '../../model/storyblokTypes.generated'
import { StoryblokCrossSellHeader } from '../StoryblokCrossSellHeader'

export const StoryblokCrossSellGrid: FC<
  StoryblokComponentProps<StoryblokCrossSellGridType>
> = ({ blok }) => {
  const { widgetId } = blok

  const { handleIntersection, intersected } = useCrossSellIntersection()

  const { data, error } = useSwrCrossSellHorizontalSlider(
    intersected && widgetId
      ? {
          visibleItemCount: 8,
          widgetId,
        }
      : undefined,
  )

  if (!widgetId || data?.items.length === 0 || error) {
    return null
  }

  return (
    <CrossSellMetadataProvider metadata={data?.metadata}>
      <CrossSellWidgetContainer
        className="w-full"
        onIntersect={handleIntersection}
        widgetData={data}
        widgetId={widgetId}
        {...storyblokEditable(blok)}
      >
        <StoryblokCrossSellHeader data={data} widgetId={widgetId} />

        <CrossSellGrid
          onGtmDataLayerPush={(cswProductId) => {
            gtmDataLayerPushCswProduct({
              cswProductId,
              widgetId,
              widgetTitle: data?.widgetdetails.title,
            })
          }}
          products={data?.items}
        />
      </CrossSellWidgetContainer>
    </CrossSellMetadataProvider>
  )
}
